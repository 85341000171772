import { ChangeEventHandler, FC, JSX, InputHTMLAttributes } from "react";
import styled, {css} from "styled-components";
import classNames from "classnames";

type OutlinedInputProps = {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeHolder?: string;
  error?: boolean;
  type?: InputHTMLAttributes<HTMLInputElement>["type"];
  color?: "transparent";
  size?: "small" | "normal";
  iconRight?: () => JSX.Element;
  fullWidth?: boolean;
};

const FieldSet = styled.div<{$fullWidth?: boolean}>`
  padding: 7px 0 0;
  position: relative;
  margin: -7px 0 0;
  ${({$fullWidth}) => $fullWidth ? css`
    width: 100%;
  ` : null}
`;

const Label = styled.label`
  color: var(--inactive);
  font-weight: 400;
  pointer-events: none;
  position: absolute;
  left: 10px;
  font-size: 16px;
  top: 16px;
  line-height: 20px;
  padding: 0 4px;
  background: var(--bgPrimary);
  transition: 0.125s ease-out;
`;

const RightIconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 7px;
  bottom: 0;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    color: var(--inactive);
  }
`;

const Input = styled.input`
  background: none;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  outline: none;
  border: 1px solid var(--inactive);
  border-radius: 6px;
  color: var(--typoPrimary);
  background: var(--bgPrimary);

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999999s;
    transition-delay: 9999999s;
  }

  &::placeholder {
    visibility: hidden;
    opacity: 0;
  }

  & ~ label {
    font-size: 12px;
    line-height: 14px;
    top: 0;
  }

  &:placeholder-shown:not(:focus) ~ label {
    font-size: 16px;
    top: 16px;
    line-height: 20px;
  }

  &:focus {
    border-color: var(--primary);

    & ~ label {
      color: var(--primary);
    }

    & ~ ${RightIconContainer} {
      svg {
        color: var(--primary);
      }
    }
  }

  &.error {
    border-color: var(--error);

    & ~ label {
      color: var(--error);
    }
  }

  &.transparent {
    background: transparent;
  }

  &.mini {
    padding: 6px 10px;
  }

  &.iconRight {
    padding-right: 40px;
  }
`;

const OutlinedInput: FC<OutlinedInputProps> = ({
  inputProps,
  placeHolder,
  value,
  onChange,
  error,
  type,
  color,
  size = "normal",
  iconRight,
  fullWidth,
}) => {
  return (
    <FieldSet $fullWidth={fullWidth}>
      <Input
        className={classNames(
          { error, mini: size === "small", iconRight },
          color
        )}
        placeholder={placeHolder}
        value={value}
        onChange={onChange}
        type={type}
        {...inputProps}
      />
      {iconRight ? (
        <RightIconContainer>{iconRight()}</RightIconContainer>
      ) : null}
      {placeHolder ? <Label>{placeHolder}</Label> : null}
    </FieldSet>
  );
};

export default OutlinedInput;
