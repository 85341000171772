import ApiInstance from "./api";

class Auth {
  async me() {
    return ApiInstance.get("/oauth_back/me", { referrerPolicy: "no-referrer" })
      .then((res) => (res.result === "successful" ? res.response : null))
      .catch(() => null);
  }

  login(login: string, password: string) {
    return ApiInstance.post("/oauth_back/login", { login, password });
  }

  redirect() {
    const redirectUrl = new URL(window.location.href).searchParams.get(
      "redirect_url",
    );
    return window.location.assign(
      redirectUrl ? decodeURIComponent(redirectUrl) : "/",
    );
  }
}

const ApiAuth = new Auth();

export default ApiAuth;
