import { useCallback, useEffect, useState } from "react";

const useGetData = <FetchData>(
  fetch: <T>(...props: any[]) => Promise<T>,
  lazy?: boolean,
) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<FetchData>();
  const [error, setError] = useState();

  const refetch = useCallback(() => {
    setLoading(true);
    fetch()
      .then((data: any) => setData(data))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (lazy) return;
    refetch();
  }, []);

  return { data, error, loading, refetch };
};


export default useGetData