class Api {
  fetch(...props: Parameters<typeof fetch>) {
    const p_host = location.protocol + '//' + location.host;
    props[0] = p_host + props[0]
    return fetch(props[0], {
      ...props[1],
    }).then((res) => res.json());
  }

  get(url: string, init?: RequestInit) {
    return this.fetch(url, {
      method: "GET",
    });
  }

  post(url: string, data: any) {
    return this.fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public prepareQueryParams(params?: { [key: string]: any }): string {
    if (!params) return "";

    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, val]) => {
      if (typeof val !== "undefined") {
        searchParams.set(key, val.toString());
      }
    });

    const queryString = searchParams.toString();

    return queryString.length > 0 ? "?" + queryString : "";
  }
}

const ApiInstance = new Api();

export default ApiInstance;
