import { FC, MouseEventHandler, PropsWithChildren } from "react";
import styled from "styled-components";

type ButtonProps = {
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const ButtonElement = styled.button<{ $fullWidth?: boolean }>`
  font-family: 'Manifold CF', serif;
  background: var(--primary);
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
  text-align: center;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : null)};
  border: none;
  color: var(--typoPrimary);
  cursor: pointer;
  &:hover{
    transition: 0.125s ease;
    background: var(--primaryHover);
  }
`;

const Button: FC<PropsWithChildren & ButtonProps> = ({
  children,
  fullWidth,
  onClick,
  disabled,
}) => {
  return (
    <ButtonElement $fullWidth={fullWidth} onClick={onClick} disabled={disabled}>
      {children}
    </ButtonElement>
  );
};

export default Button;
