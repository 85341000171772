import { createContext, FC, PropsWithChildren } from "react";
import useGetData from "../../hooks/useGetData";
import ApiAuth from "../../api/auth";

type UserContextType = {
  user: any;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);
const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: user, loading } = useGetData(ApiAuth.me);
  if (loading) return null;
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
