import { FC, useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import { FieldValues, useForm } from "react-hook-form";
import OutlinedInput from "../OutlinedInput";
import ApiInstance from "../../api/api";
import ApiAuth from "../../api/auth";

const Screen = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: "Manifold CF", serif;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    font-size: 32px;
    line-height: 38px;
  }

  h3 {
    font-family: "Manifold CF", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    color: var(--inactive);
    text-align: center;
  }
`;

const Container = styled.div`
  display: flex;
  margin: auto;
  padding: 20px;
  background: var(--bgPrimary);
  max-width: 440px;
  border-radius: 6px;
  flex-direction: column;
`;

const Caption = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  h1 {
    margin-bottom: 10px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  button {
    margin-top: 10px;
  }
`;

const ErrorSpan = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: var(--error);
  text-align: center;
`;

const Login: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState<string | undefined>();

  const onError = () => {
    setError("Не верные имя пользователя или пароль");
  };

  const onSubmit = (values: FieldValues) => {
    setError("");
    ApiAuth.login(values.login, values.password)
      .then((res) => {
        if (res.result !== "successful") return onError();
        ApiAuth.redirect();
      })
      .catch(onError);
  };

  return (
    <Screen>
      <Container>
        <Caption>
          <h1 className={"big text-center"}>ВХОД</h1>
          <h3 className={"inactive text-center"}>
            Программа для расчета взлетно-посадочных характеристик
          </h3>
        </Caption>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <OutlinedInput
            placeHolder={"Пользователь"}
            error={!!errors.name}
            inputProps={{ ...register("login", { required: true }) }}
          />
          <OutlinedInput
            placeHolder={"Пароль"}
            type={"password"}
            inputProps={{ ...register("password", { required: true }) }}
            error={!!errors.password}
          />
          {error ? <ErrorSpan>{error}</ErrorSpan> : null}
          <Button>ВОЙТИ</Button>
        </Form>
      </Container>
    </Screen>
  );
};

export default Login;
