import "./styles/styles.scss";
import Login from "./pages/login";
import UserProvider from "./providers/UserProvider";

const App = () => {
  return (
    <UserProvider>
      <Login />
    </UserProvider>
  );
};

export default App;
