import {FC, useContext} from 'react';
import Login from "../components/Login";
import {UserContext} from "../providers/UserProvider/UserProvider";
import ApiAuth from "../api/auth";

const LoginPage: FC = () => {
    const { user} = useContext(UserContext);

    if(user && user?.sub){
        ApiAuth.redirect();
        return null
    }
    return <Login />;
}

export default LoginPage;